import React from "react";
import styled from "styled-components";
import { BsLinkedin, BsFacebook, BsEnvelope, BsArrowBarUp, BsArrowUp } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
export default function Footer() {
  return (
    <FooterContainer>
      <div><h1>TOURMATE</h1></div>
      <div><span>&copy; 2023, All rights reserved by Tourmate Pune, Maharashtra</span></div>
      <div>
      <ul className="eight">
          <li>
            <a href="#hero">Home</a>
          </li>
          <li>
            <a href="#ten">About</a>
          </li>
          <li>
            <a href="#recommend">Places</a>
          </li>
          <li>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li>
            <a href="#contact">Contact us</a>
          </li>
        </ul>
      </div>
      <div className="nine">
        <span>Contact no: +91 8828405231</span>
        <span>E-mail: contact@tourmate.in</span>
        <ul className="social__links">
        <li>
          <a href="https://instagram.com/tourmate_?igshid=MmJiY2I4NDBkZg==" target="_blank">
          <BsFacebook />
          </a>
        </li>
        <li>
        <a href="https://instagram.com/tourmate_?igshid=MmJiY2I4NDBkZg==" target="_blank">
        <AiFillInstagram />
          </a>
          
        </li>
        <li>
        <a href="mailto:contact@tourmate.in" target="_blank">
        <BsEnvelope />
          </a>
          
        </li>
        </ul>
      </div>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  
  position: absolute;
  left: 0px;
  width: 100%;
  display: flex;
  flex-direction:column;
  align-items:center;
  gap:10px;
  background-color: #876E61;
  color: #FFFFFF;
  


  ul {
    display: flex;
    list-style-type: none;
    gap: 2rem;
    li {
      a {
        text-decoration: none;
        color: white;
        transition: 0.3s ease-in-out;
        &:hover {
          color: red;
        }
      }
      svg {
        font-size: 1.3rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
    }
  }
  
  .nine{
    display:flex;
    flex-direction:column;
    justify-content:space-evenly;
    gap:10px;
  }
  .eight{
    color:#FFFFFF;
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
  }
`;
