import React, { useState } from "react";
import styled from "styled-components";
import logo0 from "../assets/logo3.jpg";
import { BsLinkedin, BsFacebook, BsEnvelope, BsArrowBarUp, BsArrowUp } from "react-icons/bs";

export default function ScrollToTop() {
  const [scrollState, setScrollState] = useState(false);
  const toTop = () => {
    window.scrollTo({ top: 0 });
  };
  window.addEventListener("scroll", () => {
    window.pageYOffset > 200 ? setScrollState(true) : setScrollState(false);
  });
  return (
    <ToTop onClick={toTop} scrollState={scrollState}>
      {/* <img src={logo0} alt="" />
       */}
       <li>
        <a target="_blank">
        <BsArrowUp />
          </a>
          
        </li>
    </ToTop>
  );
}

const ToTop = styled.div`
  display: ${({ scrollState }) => (scrollState ? "block" : "none")};
  position: fixed;
  cursor: pointer;
  z-index: 10;
  bottom: 1rem;
  right: 2rem;
  img {
    height: 1.5rem;
  }
  border-radius: 2rem;
  
  color: #B76A44;
    background-color: #B76A44;
  padding: 1rem;
  li{
    color: #B76A44;
    background-color: #B76A44;
    font-weight: bold;

  }
  a{
    color: white;
    background-color: #B76A44;
    // font-size: 1.5rem;
    font-weight: bold;
  }
`;
