import React, { useState } from "react";
import styled from "styled-components";
import logo from "../assets/logo3.jpg";
import { GiHamburgerMenu } from "react-icons/gi";
import { VscChromeClose } from "react-icons/vsc";
import { BsLinkedin, BsFacebook, BsEnvelope, BsActivity, BsAlexa, BsBadge3D, BsPerson, BsAirplane } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";

export default function Navbar() {
  const [navbarState, setNavbarState] = useState(false);
  return (
    <>
      <Nav>
        <div className="brand">
          <div className="container">
            {/* <img src={logo} alt="" /> */}
            Tourmate
          </div>
          <div className="toggle">
            {navbarState ? (
              <VscChromeClose onClick={() => setNavbarState(false)} />
            ) : (
              <GiHamburgerMenu onClick={() => setNavbarState(true)} />
            )}
          </div>
        </div>

        <ul id="thirty">
          <li>
            <a href="#home">Home</a>
          </li>
          <li>
            <a href="#ten">About</a>
          </li>
          <li>
            <a href="#recommend">Places</a>
          </li>
          {/* <li>
            <a href="#testimonials">Testimonials</a>
          </li>
          <li>
            <a href="#contact">Contact us</a>
          </li> */}
          
        </ul>
        <button id="fourty"><a href="#contact">Book now</a></button>
          <ul  id="twenty" className="social__links">
          <li>
          <a href="https://instagram.com/tourmate_?igshid=MmJiY2I4NDBkZg==" target="_blank">
          <BsFacebook style={{color:'#B76A44', fontSize:'25px'}}  />
          </a>
          </li>
          <li>
          <a href="mailto:contact@tourmate.in" target="_blank">
          <BsEnvelope style={{color:'#B76A44', fontSize:'25px'}} />
          </a>
          </li>
          <li >
          <a href="https://instagram.com/tourmate_?igshid=MmJiY2I4NDBkZg==" className="ten" target="_blank">
          <AiFillInstagram style={{color:'#B76A44', fontSize:'25px'}}  />
          </a>
          </li>
          </ul>
      </Nav>
      <ResponsiveNav state={navbarState}>
        <ul>
          <li>
            <a href="#home" onClick={() => setNavbarState(false)}>
              Home
            </a>
          </li>
          <li>
            <a href="#ten" onClick={() => setNavbarState(false)}>
              About
            </a>
          </li>
          <li>
            <a href="#recommend" onClick={() => setNavbarState(false)}>
              Places
            </a>
          </li>
          <li>
            <a href="#testimonials" onClick={() => setNavbarState(false)}>
              Testimonials
            </a>
          </li>
          
        </ul>
      </ResponsiveNav>
    </>
  );
}

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  
  #twenty{
    position: absolute;
    right: 130px;
  }
  #thirty{
    position: absolute;
    left: 430px;
  }
  #fourty{
    position: absolute;
    right: 330px;
  }
  .brand {
    .container {
      color:#876E61;
      cursor: pointer;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 0.4rem;
      font-size: 1.2rem;
      font-weight: 900;
      text-transform: uppercase;
    }
    .toggle {
      display: none;
    }
  }
  ul {
    display: flex;
    gap: 1rem;
    list-style-type: none;
    li {
      a {
        text-decoration: none;
        color: #876E61;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #023e8a;
        }
      }
      &:first-of-type {
        a {
          color: #876E61;
          font-weight: 900;
        }
      }
    }
  }
  .social__links{
    display: flex;
    flex-direction:row;
    list-style-type: none;
    gap: 2rem;
    li {
      a {
        text-decoration: none;
        color: black;
        transition: 0.3s ease-in-out;
        &:hover {
        }
      }
      svg {
        
        color: #876E61;
        font-size: 1.5rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #876E61;
        }
        
      }
    }
  }
  .ten{
    
  }
  button {
    padding: 0.5rem 1rem;
    cursor: pointer;
    border-radius: 1rem;
    border: none;
    
    background-color: #B76A44;
    color: #FFFFFF;
    font-size: 1.1rem;
    font-color: #FFFFFF;
    letter-spacing: 0.1rem;
    text-transform: uppercase;
    transition: 0.3s ease-in-out;
    text-decoration:none;
    a{
      text-decoration:none;
      color: #FFFFFF;

    }
    &:hover {
      background-color: #B76A44;
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1080px) {
    .brand {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      .toggle {
        display: block;
      }
    }
    ul {
      display: none;
    }
    button {
      display: none;
    }
    .social__links {
      display:none;
    }
  }
`;

const ResponsiveNav = styled.div`
  display: flex;
  position: absolute;
  z-index: 1;
  top: ${({ state }) => (state ? "50px" : "-400px")};
  background-color: white;
  height: 30vh;
  width: 100%;
  align-items: center;
  transition: 0.3s ease-in-out;
  ul {
    list-style-type: none;
    width: 100%;
    li {
      width: 100%;
      margin: 1rem 0;
      margin-left: 2rem;

      a {
        text-decoration: none;
        color: #876E61;
        font-size: 1.2rem;
        transition: 0.1s ease-in-out;
        &:hover {
          color: #023e8a;
        }
      }
      &:first-of-type {
        a {
          color: #876E61;
          font-weight: 900;
        }
      }
    }
  }
`;
