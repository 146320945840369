import React, { useEffect } from "react";
import Footer from "./components/Footer";
import Hero from "./components/Hero";
import Navbar from "./components/Navbar";
import Recommend from "./components/Recommend";
import ScrollToTop from "./components/ScrollToTop";
import Services from "./components/Services";
import Testimonials from "./components/Testimonials";
import scrollreveal from "scrollreveal";
import Contact from "./components/contact.jsx"

export default function App() {
  // useEffect(() => {
  //   const sr = scrollreveal({
  //     origin: "top",
  //     distance: "80px",
  //     duration: 2000,
  //     reset: true,
  //   });
  //   sr.reveal(
  //     `
  //       nav,
  //       #hero,
  //       #services,
  //       #recommend,
  //       #testimonials,
  //       #contact
  //       footer
  //       `,
  //     {
  //       opacity: 0,
  //       interval: 300,
  //     }
  //   );
  // }, []);
  return (
    <div>
      <ScrollToTop />
      <Navbar />
      <Hero />
      <Recommend />
      <Services />
      <Testimonials />
      <Contact />
      <Footer />
    </div>
  );
}
