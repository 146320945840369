import homeImage1 from "../assets/blog1.jpg";
import homeImage2 from "../assets/blog2.jpg";
import homeImage3 from "../assets/blog3.jpg";
import homeImage4 from "../assets/P4.jpg";
import homeImage5 from "../assets/P5.jpg";
import homeImage6 from "../assets/P2.jpg";

const imageSlide=[
    {
      url:homeImage1
    },
    {
      url:homeImage2
    },
    {
      url:homeImage3
    },
    {
      url:homeImage4
    },
    {
      url:homeImage5
    },
    {
      url:homeImage6
    },
  ]

export default imageSlide