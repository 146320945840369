import React from "react";
import styled from "styled-components";
import service1 from "../assets/info3.png";
import service2 from "../assets/info2.png";
import service3 from "../assets/service3.png";
import service4 from "../assets/info1.png";
import { BsLinkedin, BsFacebook, BsEnvelope, BsActivity, BsAlexa, BsBadge3D, BsPerson, BsAirplane, BsDiamond, BsPalette, BsPentagon, BsPersonSquare } from "react-icons/bs";

import "../index.css"

export default function Services() {
  const data = [
    {
      icon: service1,
      title: "Customer Services",
      subTitle:
        "Our dedicated customer support team is ready to assist you throughout your journey",
    },
    {
      icon: service2,
      title: "Covid Safe",
      subTitle:
        "We have all the curated hotels that have all the precaution for a covid safe environment.",
    },
    {
      icon: {BsAirplane},
      title: "Find The Best Near You",
      subTitle:
        "Find the best hotels, resorts, guesthouses and vacation rentals to visit near you in a single click.",
    },
  ];
  return (
    <>
    <div id="ten">
        <div id="sixty">
          
          <h1>"ABOUT US"</h1>
          <p style={{fontStyle:"italic", fontSize:"18px"}}>At Tourmate Travel agncy, we believe in the transformative power of travel. with a passion for exploration we curate unforgottable journeys that immerse you in the beauty and divrsity of the world. Our team of experiened travel enthuasists are dedicated to crafting personalized  iteneraries, ensuring every detail exceeds your expectations. From thrilling adventures to relaxing gateways, let us guide you on an extraordinary travel experience that leaves you with cherished memories to last the life time. Discover the world with TOURMATE travel agency.</p>
        </div>
        {/* <div id="seventy"></div> */}
    </div>
    <Section id="services">
      
      {data.map((service, index) => {
        return (
          <div className="service">
            <div className="icon">
            
          <BsPersonSquare style={{color:'#EC7234', fontSize:'25px'}} />
          
            </div>
            <h3>{service.title}</h3>
            <p>{service.subTitle}</p>
          </div>
        );
      })}
    </Section>
    </>
  );
}

const Section = styled.section`
  padding: 5rem 0;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  .service {
    display: flex;
    flex-direction: column;
    border-radius:25px;
    gap: 1rem;
    padding: 2rem;
    width:420px;
    border: 2px solid white;

    background-color: #876E61;
      color: #FFFFFF;
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    transition: 0.3s ease-in-out;
    &:hover {
      transform: translateX(0.4rem) translateY(-1rem);
      box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    }
    .icon {
      img {
        height: 2.4rem;
       
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 720px) {
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    #ten{
   

    }
    .service{
      width:99%;
      border-radius:10px;
    }
  }
  @media screen and (min-width: 720px) and (max-width: 1080px) {
    grid-template-columns: repeat(2, 1fr);
    #ten{
      background-color: black;

    }
  }
`;
