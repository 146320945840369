import React, { useState } from "react";
import styled from "styled-components";
import Destination1 from "../assets/image1.jpeg";
import Destination2 from "../assets/image2.jpeg";
import Destination3 from "../assets/image3.jpeg";
import Destination4 from "../assets/image4.jpeg";
import Destination5 from "../assets/image5.jpeg";
import Destination6 from "../assets/image6.jpeg";
import Destination7 from "../assets/image7.jpeg";
import Destination8 from "../assets/image8.jpeg";
import Destination9 from "../assets/image9.jpeg";
import Destination10 from "../assets/image10.jpeg";
import Destination11 from "../assets/image11.jpeg";
import Destination12 from "../assets/image12.png";
import Destination13 from "../assets/image13.png";
import Destination14 from "../assets/image14.jpeg";
import Destination15 from "../assets/image15.jpeg";

import info1 from "../assets/info1.png";
import info2 from "../assets/info2.png";
import info3 from "../assets/info3.png";

export default function Recommend() {
  const data = [
    {
      image: Destination1,
      title: "Kutch",
      subTitle: "Discover the enchanting beauty and rich cultural heritage of Kutch, a captivating destination where vibrant colors, vast deserts",
      cost: "9500",
      duration: "Approx 3N 4D trip",
    },
    {
      image: Destination2,
      title: "Kedarnath",
      subTitle: "Embark on a spiritual journey to the sacred town of Kedarnath nestled amidst the Himalayas",
      cost: "9500",
      duration: "Approx 2N 3D trip",
    },
    {
      image: Destination3,
      title: "Valley of flowers and Hemkund Sahib",
      subTitle: "A serene Sikh pilgrimage site nestled amidst the Himalayas, offering breathtaking beauty and spiritual solace.",
      cost: "9000",
      duration: "Approx 5N 6D trip",
    },
    {
      image: Destination4,
      title: "Humpta Pass and Chandratal",
      subTitle: "unforgettable adventure through the majestic Himalayas and experience the thrill of crossing the breathtaking Humpta Pass",
      cost: "7500",
      duration: "Approx 4N 5D trip",
    },
    {
      image: Destination5,
      title: "Classic Ladakh with Turtuk",
      subTitle: "Experience the timeless beauty of Ladakh and discover the hidden gem of Turtuk",
      cost: "31000",
      duration: "Approx 6N 7D trip",
    },
    {
      image: Destination6,
      title: "Ladakh bike trip",
      subTitle: "Embark on an exhilarating adventure through the breathtaking landscapes of Ladakh on an unforgettable bike trip",
      cost: "21000",
      duration: "Approx 5N 6D trip",
    },
    {
      image: Destination7,
      title: "Kedarkantha",
      subTitle: "a majestic snow-capped peak in the Himalayas, and experience breathtaking vistas, pristine snowscapes, and the thrill of summiting a Himalayan summit",
      cost: "7000",
      duration: "Approx 4N 5D trip",
    },
    {
      image: Destination8,
      title: "Brahmatal Trek",
      subTitle: "Embark on a captivating winter trek to Brahmatal, where snow-covered landscapes, pristine alpine lakes, and breathtaking mountain views",
      cost: "8500",
      duration: "Approx 5N 6D trip",
    },
    {
      image: Destination9,
      title: "Kumari Pass",
      subTitle: "Embark on a breathtaking adventure through the majestic Kumari Pass, where pristine alpine landscapes and awe-inspiring Himalayan vistas await",
      cost: "10000",
      duration: "Approx 5N 6D trip",
    },
    {
      image: Destination10,
      title: "Winter Spitti",
      subTitle: "enchanting beauty of Winter Spitti, where snow-capped mountains, frozen lakes, and serene landscapes create a magical winter wonderland",
      cost: "20500",
      duration: "Approx 8N 9D trip",
    },
    {
      image: Destination11,
      title: "Dubai",
      subTitle: "Where luxury meets modernity in a desert oasis, offering iconic landmarks, world-class shopping, and unforgettable experiences",
      cost: "Customizable",
      duration: "Customizable",
    },
    {
      image: Destination12,
      title: "Singapore",
      subTitle: "Discover the vibrant blend of cultures, stunning modern architecture, and culinary delights in the captivating city-state of Singapore",
      cost: "Customizable",
      duration: "Customizable",
    },
    {
      image: Destination13,
      title: "Thailand",
      subTitle: "the vibrant culture, stunning beaches, and rich heritage of Thailand - a captivating destination that offers a blend of ancient traditions and modern wonders",
      cost: "Customizable",
      duration: "Customizable",
    },
    {
      image: Destination14,
      title: "Bangkok",
      subTitle: "Explore the vibrant metropolis of Bangkok, where ancient traditions blend with modern delights in a bustling cityscape",
      cost: "Customizable",
      duration: "Customizable",
    },
    {
      image: Destination15,
      title: "Pattaya",
      subTitle: "A vibrant coastal city offering stunning beaches, lively nightlife, and a wide array of water activities for an unforgettable tropical getaway",
      cost: "Customizable",
      duration: "Customizable",
    },
  ];

  const packages = [
    "The Weekend Break",
    "The Package Holiday",
    "The Group Tour",
    "Long Term Slow Travel",
  ];

  const [active, setActive] = useState(1);
  return (
    <Section id="recommend">
      <div className="title">
        <h1>Recommended Destinations</h1>
      </div>
      {/* <div className="packages">
        <ul>
          {packages.map((pkg, index) => {
            return (
              <li
                className={active === index + 1 ? "active" : ""}
                onClick={() => setActive(index + 1)}
              >
                {pkg}
              </li>
            );
          })}
        </ul>
      </div> */}
      <div className="destinations">
        {data.map((destination) => {
          return (
            <div className="destination">
              <img src={destination.image} alt="" />
              <h3>{destination.title}</h3>
              <p><b>{destination.subTitle}</b></p>
              <div className="info">
                <div className="services">
                  <img src={info1} alt="" />
                  <img src={info2} alt="" />
                  <img src={info3} alt="" />
                </div>
                <h4>{destination.cost}</h4>
              </div>
              <div className="distance">
                <span><b></b></span>
                <span><b>{destination.duration}</b></span>
              </div>
            </div>
          );
        })}
      </div>
    </Section>
  );
}

const Section = styled.section`
  padding: 2rem 0;
  gap: 30px;
  .title {
    text-align: center;
    padding: 2rem 0;

  }
  .packages {
    display: flex;
    justify-content: center;
    margin: 2rem 0;
    ul {
      display: flex;
      list-style-type: none;
      width: max-content;
      li {
        padding: 1rem 2rem;
        border-bottom: 0.1rem solid black;
      }
      .active {
        border-bottom: 0.5rem solid #876E61;
      }
    }
  }
  .title{
    color:#876E61;
  }
  .destinations {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 3rem;
    padding: 0 3rem;
    .destination {
      padding: 1rem;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      background-color: WHITE;
      // box-shadow: 2px 2px 2px #876E61;
      border: 2px solid #876E61;
      border-radius: 1rem;
      transition: 0.3s ease-in-out;
      h3{
        color: #EC7234;
      }
      p{
        color: #B76A44;
      }
      h4{
        color: #876E61;
      }
      .distance{
        color: #B76A44;
      }
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      img {
        width: 100%;
      }
      .info {
        display: flex;
        align-items: center;
        .services {
          display: flex;
          gap: 0.3rem;
          img {
            border-radius: 1rem;
            
            width: 2rem;
            /* padding: 1rem; */
            padding: 0.3rem 0.4rem;
          }
        }
        display: flex;
        justify-content: space-between;
      }
      .distance {
        display: flex;
        justify-content: space-between;
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .packages {
      ul {
        li {
          padding: 0 0.5rem;
          font-size: 2vh;
          padding-bottom: 1rem;
        }
        .active {
          border-bottom-width: 0.3rem;
        }
      }
    }
    .destinations {
      grid-template-columns: 1fr;
      padding: 0;
    }
  }
`;
