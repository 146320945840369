import React, { useEffect } from "react";
import styled from "styled-components";
import { useState } from "react";
import homeImage1 from "../assets/blog1.jpg";
import homeImage2 from "../assets/blog2.jpg";
import homeImage3 from "../assets/blog3.jpg";
import imageSlide from "./data"

export default function Hero() {
  const[currentState,setCurrentState]=useState(0)
  useEffect(()=>{
    const timer=setTimeout(()=>{
      if(currentState===0){
        setCurrentState(currentState+1)
      }
      else if(currentState===1){
        setCurrentState(currentState+1)
      }
      else if(currentState===2){
        setCurrentState(currentState+1)
      }
      else if(currentState===3){
        setCurrentState(currentState+1)
      }
      else if(currentState===4 ){
        setCurrentState(currentState+1)
      }
      else{
        setCurrentState(0)
      }
    },5000)
    return ()=>clearTimeout(timer)
  },[currentState])

  
  return (
    <Section id="hero">
      <div className="background">
        <img src={imageSlide[currentState].url} alt="" />
      </div>
      <div className="content">
        <div className="title">
          <h1>Explore with Tourmate</h1>
          <p>
            Discover the world through our extensive collection of destination. Make the most of your destination with our curated list of activities and tour. Explore breathtaking beaches, vibrant cities, historic landmarks. 
          </p>
        </div>
        
      </div>
    </Section>
  );
}

const Section = styled.section`
  position: relative;
  margin-top: 2rem;
  width: 100%;
  height: 100%;

  .background {
    height: 100%;
    img {
      width: 100%;
      filter: brightness(60%);
    }
  }
  .content {
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 3;
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    .title {
      color: white;
      h1 {
        font-size: 3rem;
        letter-spacing: 0.2rem;
      }
      p {
        text-align: center;
        padding: 0 30vw;
        margin-top: 0.5rem;
        font-size: 1.2rem;
      }
    }
    .search {
      display: flex;
      
      padding: 0.5rem;
      border-radius: 0.5rem;
      .container {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        padding: 0 1.5rem;
        label {
          font-size: 1.1rem;
          color: #03045e;
        }
        input {
          background-color: transparent;
          border: none;
          text-align: center;
          color: black;
          &[type="date"] {
            padding-left: 3rem;
          }

          &::placeholder {
            color: black;
          }
          &:focus {
            outline: none;
          }
        }
      }
      button {
        padding: 1rem;
        cursor: pointer;
        border-radius: 0.3rem;
        border: none;
        color: white;
        background-color: #4361ee;
        font-size: 1.1rem;
        text-transform: uppercase;
        transition: 0.3s ease-in-out;
        &:hover {
          background-color: #023e8a;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 980px) {
    height: 25rem;
    .background {
      
      img {
        height: 100%;
      }
    }
    .content {
      .title {
        h1 {
          font-size: 1rem;
        }
        p {
          font-size: 0.8rem;
          padding: 1vw;
        }
      }
      .search {
        flex-direction: column;
        padding: 0.8rem;
        gap: 0.8rem;
        /* padding: 0; */
        .container {
          padding: 0 0.8rem;
          input[type="date"] {
            padding-left: 1rem;
          }
        }
        button {
          padding: 1rem;
          font-size: 1rem;
        }
        /* display: none; */
      }
    }
  }
`;
