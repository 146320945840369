import React from "react";
import styled from "styled-components";
import avatarImage1 from "../assets/i1.jpg";
import avatarImage2 from "../assets/i2.jpg";
import avatarImage3 from "../assets/i3.jpg";

export default function Testimonials() {
  return (
    <Section id="testimonials">
      <div className="title">
        <h1>Happy Customers</h1>
      </div>
      <div className="testimonials">
        <div className="testimonial">
          <p>
            I have been booking through Tourmate for several years and they never disappoint me. They go above above and beyond to ensure a smooth travel experience, making them my go-to travel agency.
          </p>
          <div className="info">
            <img src={avatarImage1} alt="" />
            <div className="details">
              <h4>Mr.Nikhil</h4>
              <span>Journalist</span>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <p>
            Our family had an incredible experience booking our vaccation through Tourmate.The customer support team was helpfull and responsive. I highly recommend Tourmate for their excellent services and seamless booking process.
          </p>
          <div className="info">
            <img src={avatarImage2} alt="" />
            <div className="details">
              <h4>Mr.Sahil</h4>
              <span>Student</span>
            </div>
          </div>
        </div>
        <div className="testimonial">
          <p>
            The website's interface is clean and organized, making it easy to find and navigate the information I needed. I had a fantastic trip and I will definately be using Tourmate again in the future. 
          </p>
          <div className="info">
            <img src={avatarImage3} alt="" />
            <div className="details">
              <h4>Mr.Prithviraj</h4>
              <span>Engineer</span>
            </div>
          </div>
        </div>
      </div>
    </Section>
  );
}

const Section = styled.section`
  margin: 5rem 0;
  .title {
    text-align: center;
    margin-bottom: 2rem;
    color:#876E61;
    h1{
      color: #B76A44;
    }
  }
  .testimonials {
    h4{
      color: #EC7234;
    }
    span{
      color: #EC7234;
    }
    display: flex;
    justify-content: center;
    // margin: 0 2rem;
    gap: 2rem;
    .testimonial {
      // width:200px;
      background-color: #FFFFFF;
      color: #876E61;
      padding: 2rem;
      border-radius: 0.5rem;
      border: 2px solid #876E61;

      box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
      transition: 0.3s ease-in-out;
      &:hover {
        transform: translateX(0.4rem) translateY(-1rem);
        box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
      }
      .info {
        display: flex;
        justify-content: center;
        gap: 1rem;
        align-items: center;
        margin-top: 1rem;
        img {
          border-radius: 3rem;
          height: 3rem;
        }
        .details {
          span {
            font-size: 0.9rem;
          }
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 768px) {
    .testimonials {
      flex-direction: column;
      margin: 0;
      .testimonial {
        justify-content: center;
        .info {
          flex-direction: column;
          justify-content: center;
        }
      }
    }
  }
`;
