import React from "react";
import styled from "styled-components";
import { BsLinkedin, BsFacebook } from "react-icons/bs";
import { AiFillInstagram } from "react-icons/ai";
import hero1 from "../assets/hero.png"
import d1 from "../assets/P1.jpg"
import "../index.css"
export default function Contact() {
  return (
    <FooterContainer id="contact">
      <h2 className="title"> Feel Free to contact</h2>
      <div className="five">
        <div className="six" >
        </div>
        <div className="seven">
        <h1>DREAM HOLIDAY</h1>
        <h1>ONE BOOKING AWAY</h1>
        <form action="https://formspree.io/f/xayzryre" method="POST">
        <p id="eleven">NAME</p>
        <input type="text" name="Name" placeholder="Name" required/>
        <p id="eleven">Destination</p>
        <input type="textrea" name="Destination" placeholder="Destination" required/>
        <p id="eleven">Phone number</p>
        <input type="phone" name="Phone" placeholder="Phone Number" required/>
        <p id="eleven">Number of people</p>
        <input type="text" name="number of people" placeholder="Number of people" required/>
        <p id="eleven">Date</p>
        <input type="date" name="Date" placeholder="Date" required/>
        <button>BOOK NOW</button>
      </form>
        </div>
      </div>
    </FooterContainer>
  );
}

const FooterContainer = styled.footer`
  display: flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;

  
  
  border-radius: 0.5rem;
  padding: 2.5rem;
  button{
    margin:10px;
    width:100px;
  }
  .title{
    color: #B76A44;
    padding: 1.3rem;

  }
  #eleven{
    font-size:bold;
    font:bold;
    text-style:bold;
    font-weight:bold;
  }
  form{
    display:flex;
    flex-direction:column;
    padding:20px;
  }
  input{
    padding:5px;
    margin:10px;
    width:290px;
  }
  textarea{
    padding:5px;
    
  }
  .five{
    display:flex;
    flex-direction:row;
    border-radius:20px;
    border: 2px solid #876E61;
    height: 600px;
    // width:810px;

  }
  .six{
    width:810px;
    height:500px;
    
   
  }
  .seven{
    background-color:white;
    
    width:410px;
    height:500px;
    padding: 0px 20px;
    position: relative;
    top: 40px;
    right: 40px;
    border-radius:20px;
    // margin:20px
    button{
      background-color:orange;
    }
    
  }
  ul {
    display: flex;
    list-style-type: none;
    gap: 2rem;
    li {
      a {
        text-decoration: none;
        color: black;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
      svg {
        font-size: 1.3rem;
        transition: 0.3s ease-in-out;
        &:hover {
          color: #302ce9;
        }
      }
    }
  }
  @media screen and (min-width: 280px) and (max-width: 1024px) {
    flex-direction: column;
    gap: 2rem;
    .title{
      color: #B76A44;
      padding: 1px;
  
    }
    ul {
      flex-direction: column;
    }
    .social__links {
      flex-direction: row;
    }
    .five{
      
      margin:20px;
      height: 800px;
      width:380px;
      // background-image: url("../assets/image16.svg");

      // background-repeat: no-repeat;
      // background-size: 100% 100%;
      
    }
    
    .seven{
      p{
        color: #EC7234;
        font-size:13px;

      }
      h1{
        color:#EC7234;
        font-size:18px;
      }
      form{
        // width:100px;
        input{
          width: 210px;
          height: 25px;
        }
      }
      width:85%;
      height: 62%;
      display:flex;
      flex-direction:column;
      justify-content:center;
      align-items:center;
      position:relative;
      right:10%;
      top: 70px;
      border-radius:15px;
      background-color: white;
      // opacity: 45%;
      button{
        positon: relative;
        right:120px;
        background-color: #EC7234;
      }
    }
  }
`;
